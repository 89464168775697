import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/admin',
      name: 'admin',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/automate-class',
      name: 'automate-class',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Automate Class',
        breadcrumb: [
          {
            text: 'Automate Class',
            active: true,
          },
        ],
      },
    },
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgotpass',
      component: () => import('@/views/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: () => import('@/views/pages/pricing/Pricing.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'resetpass',
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/account-setting',
      name: 'pages-account-setting',
      component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
      meta: {
        pageTitle: 'Account Settings',
        breadcrumb: [
          {
            text: 'Account Settings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
router.beforeEach((to, from, next) => {
  if (!store.state.app.isAuthenticated) {
    store.dispatch('app/checkAuthState').then(() => {
      if (to.name !== 'login' && to.name !== 'register' && to.name !== 'forgotpass' && to.name !== 'resetpass' && to.name !== 'pricing' && !store.state.app.isAuthenticated ) next({ name: 'login' })
      else if (to.name === 'login' && store.state.app.isAuthenticated) next({ name: 'admin' })
      else next()
    })
  } else if (to.name !== 'login' && to.name !== 'register' && to.name !== 'forgotpass' && to.name !== 'resetpass' && to.name !== 'pricing' && !store.state.app.isAuthenticated) next({ name: 'login' })
  else if (to.name === 'login' && store.state.app.isAuthenticated) next({ name: 'admin' })
  else next()
})

export default router
