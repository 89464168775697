/* eslint-disable */
import { $themeBreakpoints } from '@themeConfig'
import $http from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    isAuthenticated: false,
    isLoading: false,
    user_data: {},
    subdomain: '',
    integrationstate: {},
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    authstate(state, val) {
      if(val){
        state.user_data = val;
        state.isAuthenticated = true
      }
      else{
        state.isAuthenticated = val
      }
    },
    setIntstate(state, val){
      state.integrationstate = val
    },
    loadingState(state, val) {
      state.isLoading = val
    },
    updateSubdomain(state, val) {
      state.subdomain = val
    },
  },
  actions: {
    checkAuthState(context) {
      return new Promise((resolve, reject) => {
        $http.get('https://live.ipstudio.co/api/get-state')
        .then(res => {
          if(res.data.status == "loggedin"){
            context.commit('authstate', res.data.data);
          }
          else{
            context.commit('authstate', false);
          }
          resolve(res.data.status);
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
      })
    },
    saveUserMeta(context, payload) {
      var self = this
      context.commit('loadingState', true)
      $http.post('https://live.ipstudio.co/api/save-usermeta', payload)
      .then(res => {
        context.commit('loadingState', false)
        self._vm.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.msg,
            icon: 'SaveIcon',
            variant: res.data.status,
          }
        })
      })
      .catch(function (error) {
        console.log(error)
        context.commit('loadingState', false)
        self._vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong.',
            icon: 'AlertCircleIcon',
            variant: 'failed',
          }
        })
      })
    },
    async getSubdomain(context) {
      const data = await $http.post('https://live.ipstudio.co/api/get-usermeta', { meta_key: 'mariana' })
      const meta_value = JSON.parse(data.data.meta_value);
      context.commit('updateSubdomain', meta_value.subdomain)
    },
    getIntegrationStatus(context){
      context.commit('loadingState', true)
      $http.get('https://live.ipstudio.co/integration-status')
      .then(res => {
        context.commit('setIntstate', res.data);
        context.commit('loadingState', false)
      })
    }
  },
}
